import $ from 'jquery';
import Swal from 'sweetalert2';

export default function() {
 sendTicketMessage(); 
 markAsSolved();
 insertTicket();
}

function sendTicketMessage() 
{
  $('.js-send-message').on('submit', function(e) {
    e.preventDefault();

    if(!$(this).valid())
      return;

    const formData = new FormData($(this)[0]);
    const $that = $(this);
    window.waitMe($(this), $(this).data('loading-text'));

    $.ajax({
      url: window.GLOBALS.baseLink + '/panel/destek-talepleri/mesaj-gonder',
      type: 'POST',
      data: formData,
      dataType: 'json',
      contentType: false,
      processData: false,
      success: function(response) {
        if (response) {
          if (response.IsSuccess) {
            Swal.fire(response.Title, response.Message, response.Class,
            );
            if (response.Redirect) {
              setTimeout(function () {
                window.location.href = response.Redirect;
              }, 1000)
            }
            window.closeWaitMe($that);
          } else {
            Swal.fire(response.Title, response.Message, {
              icon: response.Class,
              buttons: false
            });
            window.closeWaitMe($that);
          }
        }
      }
    })
  })
}

function markAsSolved()
{
  $('.js-mark-as-solved').on('click', function(e){
    e.preventDefault();
    
    const ticketId = $(this).data('ticket-id');
    if(ticketId)
    {

      Swal.fire({
        title: 'Emin misiniz ?',
        text: "Destek talebiniz çözüldü olarak işaretlenecektir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#198754',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Vazgeç',
        confirmButtonText: 'Evet, Çözüldü!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            url: window.GLOBALS.baseLink + '/panel/destek-talepleri/cozuldu',
            type: 'POST',
            data: {"TicketID": ticketId},
            dataType: 'json',
            success: function(response) {
              if (response) {
                if (response.IsSuccess) {
                  Swal.fire(response.Title, response.Message, response.Class,
                  );
                  if (response.Redirect) {
                    setTimeout(function () {
                      window.location.href = response.Redirect;
                    }, 1000)
                  }
                  window.closeWaitMe($that);
                } else {
                  Swal.fire(response.Title, response.Message, {
                    icon: response.Class,
                    buttons: false
                  });
                  window.closeWaitMe($that);
                }
              }
            }
          })
        }
      })
      
    }

  })
}

function insertTicket()
{
  $('.js-insert-ticket').on('submit', function(e){
    e.preventDefault();

    if(!$(this).valid())
      return;

    const formData = new FormData($(this)[0]);
    const $that = $(this).closest('.modal-dialog');
    window.waitMe($(this).closest('.modal-dialog'), $(this).data('loading-text'));
    $(document).find('.js-create-ticket-button').prop('disabled', true);
    $(document).find('.js-cancel-ticket-button').prop('disabled', true);

    $.ajax({
      url: window.GLOBALS.baseLink + 'panel/destek-talepleri/talep-ekle',
      type: 'POST',
      data: formData,
      dataType: 'json',
      contentType: false,
      processData: false,
      success: function(response) {
        if (response) {
          if (response.IsSuccess) {
            Swal.fire(response.Title, response.Message, response.Class,
            );
            if (response.Redirect) {
              setTimeout(function () {
                window.location.href = response.Redirect;
              }, 1000)
            }
            window.closeWaitMe($that);
            $(document).find('.js-create-ticket-button').prop('disabled', false);
            $(document).find('.js-cancel-ticket-button').prop('disabled', false);
          } else {
            Swal.fire(response.Title, response.Message, {
              icon: response.Class,
              buttons: false
            });
            window.closeWaitMe($that);
            $(document).find('.js-create-ticket-button').prop('disabled', false);
            $(document).find('.js-cancel-ticket-button').prop('disabled', false);
          }
        }
      }
    })
  })
}