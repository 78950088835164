import $ from 'jquery';
import 'select2';
import '../../../node_modules/select2/dist/js/i18n/tr';
import '../../../node_modules/waitme/waitMe';
import { isMobile } from './constants';
import callCenterForm from './callCenterForm';


export default function () {
  $(function () {
    $('form').validate();

    $('.js-select').select2({
      'width': '100%',
      'language': 'tr',
    })

    $('.js-select-tag').select2({ 
      'width': '100%',
      'tags': true,
      'language': 'tr',
      minimumInputLength: 2,
      maximumSelectionLength: 10
    })

    window.waitMe = function(container, text) {
      $(container).waitMe({
          effect : 'bounce',
          text : text,
          bg : 'rgba(255,255,255,0.65)',
          color : '#000',
          maxSize : '',
          waitTime : -1,
          textPos : 'vertical',
          fontSize : '',
          source : '',
          onClose : function() {}
      });
    }

    window.closeWaitMe = function(container) {
      $(container).waitMe('hide');
    }

    if(jQuery.validator.messages){

      $.validator.setDefaults({
        highlight: function (element) {
          $(element).addClass('border-bottom border-danger');
        },
        unhighlight: function (element) {
          $(element).removeClass('border-bottom border-danger');
        },
        errorElement: "span",
        errorPlacement: function (error, element) {
          console.log(error);
          $(element).closest('.form-group').find('input').after(error);

          if($(element).attr('type') == 'checkbox'){
            $(element).closest('label').find('.js-error-msg').html(error);
          }
          return true;
        }
      });
  
      var random = Math.random(0,20)
    
      jQuery.extend(jQuery.validator.messages, {
          required: "Bu alanın doldurulması zorunludur.",
          remote: "Lütfen bu alanı düzeltin.",
          email: "Lütfen geçerli bir e-posta adresi giriniz.",
          url: "Lütfen geçerli bir web adresi (URL) giriniz.",
          date: "Lütfen geçerli bir tarih giriniz.",
          dateISO: "Lütfen geçerli bir tarih giriniz(ISO formatında)",
          number: "Lütfen geçerli bir sayı giriniz.",
          digits: "Lütfen sadece sayısal karakterler giriniz.",
          creditcard: "Lütfen geçerli bir kredi kartı giriniz.",
          equalTo: "Lütfen aynı değeri tekrar giriniz.",
          extension: "Lütfen geçerli uzantıya sahip bir değer giriniz.",
          maxlength: $.validator.format("Lütfen en fazla {0} karakter uzunluğunda bir değer giriniz."),
          minlength: $.validator.format("Lütfen en az {0} karakter uzunluğunda bir değer giriniz."),
          rangelength: $.validator.format("Lütfen en az {0} ve en fazla {1} uzunluğunda bir değer giriniz."),
          range: $.validator.format("Lütfen {0} ile {1} arasında bir değer giriniz."),
          max: $.validator.format("Lütfen {0} değerine eşit ya da daha küçük bir değer giriniz."),
          min: $.validator.format("Lütfen {0} değerine eşit ya da daha büyük bir değer giriniz."),
          require_from_group: $.validator.format("Lütfen bu alanların en az {0} tanesini doldurunuz.")
      });
    
      $.validator.methods.number = function (value, element) {
          return this.optional(element) || /-?(?:\d+|\d{1,3}(?:[\s\.,]\d{3})+)(?:[\.,]\d+)?$/.test(value);
      }
    }

    dynamicHeightForPackage();
  })

  $(document).on('click', 'a[href="#"]', function(e) {
    e.preventDefault();
  })

  callCenterForm();
}

function dynamicHeightForPackage() {
  if(isMobile)
  {
    $.each($('.js-package-item'), function(i,e) {
      const getUlHeight = $(e).find('.js-package-item-content ul').height();
      $(e).find('.js-package-item-content').css('min-height', getUlHeight + 'px');
    })
  }
}