import $ from 'jquery';
import Swal from 'sweetalert2';

export default function() {
  updateProfile()
  updatePassword()
}

function updateProfile() 
{
  $('.js-save-profile').on('submit', function(e){
    e.preventDefault();

    const formData = new FormData($(this)[0]);
    const $that = $(this);
    window.waitMe($(this), $(this).data('loading-text'));
    
    $.ajax({
        url: window.GLOBALS.baseLink + '/panel/profil-guncelle',
        type: 'POST',
        data: formData,
        dataType: 'json',
        contentType: false,
        processData: false,
        success: function(response) {
          if (response) {
            if (response.IsSuccess) {
              Swal.fire(response.Title, response.Message, response.Class,
              );
              if (response.Redirect) {
                setTimeout(function () {
                  window.location.href = response.Redirect;
                }, 1000)
              }
              window.closeWaitMe($that);
            } else {
              Swal.fire(response.Title, response.Message, {
                icon: response.Class,
                buttons: false
              });
              window.closeWaitMe($that);
            }
          }
        }
      })
  })
}

function updatePassword()
{
  $('.js-update-password').on('submit', function(e) {
    e.preventDefault();

    $('.js-update-password').validate({
      rules: {
        NewPassword: "required",
        NewPasswordAgain: {
          equalTo: "#NewPassword"
        }
      }
    });
    const that = $(this);

    if(!$(this).valid()) 
    {
      return;
    }

    var formData = new FormData($(this)[0]);
    window.waitMe($(this), $(this).data('loading-text'));

    $.ajax({
      url: window.GLOBALS.baseLink + '/panel/sifre-guncelle',
      type: 'POST',
      data: formData,
      dataType: 'json',
      contentType: false,
      processData: false,
      success: function(response) {
        if (response) {
          if (response.IsSuccess) {
            Swal.fire(response.Title, response.Message, response.Class,
            );
            if (response.Redirect) {
              setTimeout(function () {
                window.location.href = response.Redirect;
              }, 1000)
            }
            window.closeWaitMe(that);
          } else {
            Swal.fire(response.Title, response.Message, response.Class
            );
            // window.closeWaitMe('.js-update-password');
            window.closeWaitMe(that);
          }
        }else {
          window.closeWaitMe(that);
        }
      }
    })
  })
}