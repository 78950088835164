import $ from 'jquery';
import "../../../node_modules/slick-carousel/slick/slick";
import 'slick-carousel/slick/slick';
import Swal from 'sweetalert2';

export default function () {
    $(function () {
        $('.js-top-slider').slick({
            slidesToShow: 1,
        });
    })

    sendContactForm();
}

function sendContactForm() {
    $('.js-contact-form').on('submit', function (e) {
        e.preventDefault();

        if (!$(this).valid())
            return;

        const formData = new FormData($(this)[0]);
        const $that = $(this);
        window.waitMe($(this), $(this).data('loading-text'));

        formData.append('Subject', 'Anasayfa İletişime Geç Alanı');

        $.ajax({
            url: window.GLOBALS.baseLink + 'mesaj-gonder',
            type: 'POST',
            data: formData,
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        Swal.fire(response.Title, response.Message, response.Class, );
                        if (response.Redirect) {
                            setTimeout(function () {
                                window.location.href = response.Redirect;
                            }, 1000)
                        }
                        window.closeWaitMe($that);
                    } else {
                        Swal.fire(response.Title, response.Message, response.Class);
                        window.closeWaitMe($that);
                    }
                }
            }
        })
    });
}
