import homepage from 'js/homepage/';
import auth from 'js/auth';
import profile from 'js/profile';
import ticket from 'js/ticket';
import contact from 'js/contact';
import service from 'js/service';

const globals = window.GLOBALS;

const route = {
    homepage: homepage,
    auth: auth,
    profile: profile,
    ticket: ticket,
    contact: contact,
    service
}

if(typeof globals.page != 'undefined') {
    globals.page.constructor === Array ?
    globals.page.map((item) => {
        route && route[item] && route[item]()
        })
        :
        route && route[globals.page] && route[globals.page]();
  }