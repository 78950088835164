import $ from 'jquery';
import Swal from 'sweetalert2';

export default function() {
  $('.js-save-callcenter-form').on('submit', function(e) {
    e.preventDefault();

    if (!$(this).valid())
      return;

    const formData = new FormData($(this)[0]);
    const $that = $(this);
    window.waitMe($(this));

    $.ajax({
      url: window.GLOBALS.baseLink + '/sifirsermaye/gonder',
      type: 'POST',
      data: formData,
      dataType: 'json',
      contentType: false,
      processData: false,
      success: function(response) {
        if (response) {
          if (response.IsSuccess) {
            Swal.fire(response.Title, response.Message, response.Class);
            if (response.Redirect) {
              setTimeout(function () {
                window.location.href = response.Redirect;
              }, 1000)
            }
            window.closeWaitMe($that);
          } else {
            Swal.fire(response.Title, response.Message, response.Class);
            window.closeWaitMe($that);
          }
        }
      }
  })

  })
}