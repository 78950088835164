import $ from 'jquery';

const windowWidth = $(window).width();
export const MOBILE_WIDTH = 768;
export const TABLET_WIDTH = 992;
export const isMobile = windowWidth < MOBILE_WIDTH;
export const isTablet = windowWidth < TABLET_WIDTH;
export const domainRegex = /(^|\s)([\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
export const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export function scrollToElement(element, speed)
{
  $('html, body').animate({
    scrollTop:$(element)
      .position().top - 200
    }, speed);
}

export function getCityByCountry(select, target, name)
{
  $(select).on('change', function(e) {
    e.preventDefault();
    const countryID = $(this).val();
    const $targetElement = $(target);

    if(countryID)
    {
      $.ajax({
        url: window.GLOBALS.baseLink + 'get-city-by-country',
        type: 'GET',
        data: {
          'CountryID': countryID
        },
        dataType: 'json',
        success: function(response) {
          $targetElement.removeClass('d-none');            
          $targetElement.find('option').remove();
          if(response && response.CityList)
          {
            $.each(response.CityList, function(i,e){
              $targetElement.append($('<option>', { 
                value: e.CityID,
                text : e.CityName
              }));
            })
          }else {
            $targetElement.append('<option>Şehir bulunamadı</option>')
          }
        }
      })
    }
  })
}

export function tl_format(price)
{
  if(price == "0")
  {
    return price;
  }

  if(price)
  {
    const formatter = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
    });
    return formatter.format(price);
  }
}