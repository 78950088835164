import '../less/index.less'

import $ from 'jquery'
window.jQuery = $
window.$ = $

const WOW = require('wowjs');

window.wow = new WOW.WOW({
    live: false
});

window.wow.init();

import 'js/appRouter'
import './theme-files/jquery.min.js';
import '../../node_modules/jquery-validation/dist/localization/messages_tr';
import '../../node_modules/jquery-validation/dist/jquery.validate';
import '../../node_modules/jquery-mask-plugin/dist/jquery.mask';
import './theme-files/bootstrap.bundle.min.js';
import './theme-files/owl.carousel.min.js';
import './theme-files/jquery.magnific-popup.min.js';
import './theme-files/jquery.nice-select.min.js';
import './theme-files/meanmenu.js';
import './theme-files/jquery.ajaxchimp.min.js';
import './theme-files/form-validator.min.js';
import './theme-files/contact-form-script.js';
import './theme-files/custom.js';

import './common/weepay.min.js';
import common from 'js/common/index';


(function () {
  common();

  $('[data-phone-mask]').mask("0(500) 000 00 00", {
    placeholder: "0(500) 000 00 00"
  });
  $('[data-date-mask]').mask("00.00.0000", {
    placeholder: "gg.aa.YYYY"
  });
})();
